import * as React from "react";
import styled from "styled-components";
import { graphql, Link } from "gatsby";
import { IndexPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { Meta } from "../components/Meta";
import { theme } from "../components/theme";
import { ActivityList } from "../components/ActivityList";
import { SectionTitle } from "../components/SectionTitle";
import { HorizonList } from "../components/HorizonList";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryView } from "../components/imageGalleryView";
import MediaQuery from "react-responsive";
import { Avatar } from "@material-ui/core";
import { SNSLink } from "../components/SNSLink";
import { Helmet } from "react-helmet";

const ImageWidthPC = "w_1200";
const ImageWidthSP = "w_768";

interface IndexPageProps {
  data: IndexPageQuery;
}

const Home: React.FC<IndexPageProps> = ({ data }) => {
  const imagesPC: ReactImageGalleryItem[] = [
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322567/web/home001_bexvqr.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602328823/web/home002_hcquko.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322571/web/home003_slmoq6.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602323939/web/home004_ikoy1a.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602328823/web/home005_jgsawp.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602328823/web/home006_fz19cp.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322569/web/home007_eufrmg.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322569/web/home008_wvfwak.png`,
    },
  ];

  const imagesSP: ReactImageGalleryItem[] = [
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1602322567/web/home001_bexvqr.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1602328823/web/home002_hcquko.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1602322571/web/home003_slmoq6.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1602323939/web/home004_ikoy1a.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthSP}/v1602328823/web/home005_jgsawp.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602328823/web/home006_fz19cp.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322569/web/home007_eufrmg.png`,
    },
    {
      original: `https://res.cloudinary.com/mogflowts/image/upload/${ImageWidthPC}/v1602322569/web/home008_wvfwak.png`,
    },
  ];

  const itemList = data.allContentfulActivity.edges.map((item) => {
    return <ActivityList key={item.node.contentful_id} activity={item} />;
  });

  return (
    <Layout>
      <Meta />
      <Helmet>
        <title>{`mogFlowts - Top`}</title>
      </Helmet>

      <>
        {/* SP */}
        <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
          <TopLogo>
            <ImageGalleryView items={imagesSP} />
          </TopLogo>

          <SNSLink />

          <Container>
            <TitleContainer>
              <SectionTitle>WORKS</SectionTitle>
            </TitleContainer>
            <WorkContainer>
              {data.allContentfulWork.edges.map((item) => {
                return (
                  <Link
                    key={item.node.contentful_id}
                    to={`/work/${item.node.contentful_id}`}
                  >
                    <img
                      alt={"WorkImage"}
                      src={item.node.thumbnails[0].file.url}
                    />
                  </Link>
                );
              })}
            </WorkContainer>

            <TitleContainer>
              <SectionTitle>ACTIVITIES</SectionTitle>
            </TitleContainer>
            <ActivityContainerSP>
              {data.allContentfulActivity.edges.map((item) => {
                return (
                  <Link
                    key={item.node.contentful_id}
                    to={`/activity/${item.node.contentful_id}`}
                  >
                    <ActiveContentSP>
                      <img
                        src={`${item.node.heroImage.file.url}?w=500`}
                        alt={"ActivityImage"}
                      />
                      <div>{item.node.date}</div>
                      <div>{item.node.title}</div>
                      <div>
                        <Avatar
                          alt="Author"
                          src={`${item.node.author.avatorImage.file.url}?w=50`}
                        />
                        {item.node.author.name}
                      </div>
                    </ActiveContentSP>
                  </Link>
                );
              })}
            </ActivityContainerSP>
          </Container>
        </MediaQuery>

        {/* PC */}
        <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
          <SNSLink />

          <TopLogo>
            <ImageGalleryView items={imagesPC} />
          </TopLogo>

          <Container>
            <SectionTitle>ACTIVITIES</SectionTitle>
            <ActivityContainer>
              <HorizonList itemList={itemList} />
            </ActivityContainer>
            <SectionTitle>WORKS</SectionTitle>
            <WorkContainer>
              {data.allContentfulWork.edges.map((item) => {
                return (
                  <Link
                    key={item.node.contentful_id}
                    to={`/work/${item.node.contentful_id}`}
                  >
                    <img
                      alt={"WorkImage"}
                      src={item.node.thumbnails[0].file.url}
                    />
                  </Link>
                );
              })}
            </WorkContainer>
          </Container>
        </MediaQuery>
      </>
    </Layout>
  );
};

export const pageQuery = graphql`
  query IndexPage {
    allContentfulWork(sort: { fields: date, order: DESC }) {
      edges {
        node {
          contentful_id
          thumbnails {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulActivity(sort: { fields: date, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          date
          heroImage {
            file {
              url
            }
          }
          author {
            avatorImage {
              file {
                url
              }
            }
            name
          }
        }
      }
    }
  }
`;

export default Home;

const TopLogo = styled.div`
  width: 100vw;
  background-color: grey;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 15px auto 0;
    width: 1024px;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 15px 80px;
    width: ${theme.width.main};
  }
`;

const TitleContainer = styled.div`
  padding-left: 15px;
`;

const ActivityContainer = styled.div`
  margin: 30px auto 100px;
`;

const ActivityContainerSP = styled.div`
  margin: 15px auto 30px;

  width: 100%;
`;
const ActiveContentSP = styled.div`
  padding: 15px;

  border: 0.5px ${theme.colors.grey20};
  border-bottom-style: solid;

  // date
  & > div:nth-child(2) {
    font-size: 0.6em;
  }

  // tuitle
  & > div:nth-child(3) {
    color: ${theme.colors.grey60};
    font-weight: 600;
  }

  // UserInfo
  & > div:nth-child(4) {
    margin-top: auto;
    margin-bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    & > :nth-child(1) {
      margin-right: 15px;
    }
  }

  & > img {
    width: 100%;
    max-height: 70vw;
    justify-self: center;
    border-radius: 8px;
    cursor: pointer;
    object-fit: cover;
    border: 1px solid ${theme.colors.grey20};
  }
`;

const WorkContainer = styled.div`
  padding: 15px;
  margin: 15px auto 30px;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  img {
    width: 100%;
    justify-self: center;
    border-radius: 8px;
    cursor: pointer;
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    margin: 30px auto 0;

    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
`;
