import * as React from "react";
import { FC } from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { navigate } from "gatsby";

interface HorizonListProps {
  itemList: JSX.Element[];
}

const HorizonList: FC<HorizonListProps> = ({ itemList }) => {
  interface ArrowProps {
    text: string;
    className: string;
  }
  const Arrow: FC<ArrowProps> = ({ text, className }) => {
    return (
      <div className={className} style={{ fontSize: "32px" }}>
        {text}
      </div>
    );
  };
  const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
  const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

  return (
    <ScrollMenu
      data={itemList}
      clickWhenDrag={false}
      dragging={true}
      wheel={false}
      arrowLeft={ArrowLeft}
      arrowRight={ArrowRight}
      hideSingleArrow={true}
      scrollBy={5}
      onSelect={e => navigate(`/activity/${e}`)}
      translate={1}
      transition={1}
      inertiaScrolling={true}
      inertiaScrollingSlowdown={1}
      alignCenter={true}
    />
  );
};

export { HorizonList };
