import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { theme } from "../theme";
import {
  ContentfulActivity,
  ContentfulAssetFile,
  ContentfulAuthor,
  Maybe
} from "../../../types/graphql-types";

interface ActivityListProps {
  activity: {
    node: Pick<ContentfulActivity, "contentful_id" | "title" | "date"> & {
      heroImage?: Maybe<{ file?: Maybe<Pick<ContentfulAssetFile, "url">> }>;
      author?: Maybe<
        Pick<ContentfulAuthor, "name"> & {
          avatorImage?: Maybe<{
            file?: Maybe<Pick<ContentfulAssetFile, "url">>;
          }>;
        }
      >;
    };
  };
}

const ActivityList: FC<ActivityListProps> = ({ activity }) => {
  return (
    <ActivityLogContainer imageURL={activity.node.heroImage.file.url}>
      <div />
      <div>
        <div>{activity.node.date}</div>
        <div style={{ whiteSpace: "pre-wrap" }}>{activity.node.title}</div>
        <div>
          <Avatar
            alt="Author"
            src={`${activity.node.author.avatorImage.file.url}?w=50`}
          />
          {activity.node.author.name}
        </div>
      </div>
    </ActivityLogContainer>
  );
};

export { ActivityList };

interface ActivityLogContainerProps {
  imageURL: string;
}

const ActivityLogContainer = styled.div<ActivityLogContainerProps>`
  margin: 0 50px;

  width: 300px;
  height: 350px;
  user-select: none;

  cursor: pointer;

  // iamge
  & > div:nth-child(1) {
    width: 300px;
    height: 200px;
    border-radius: 8px;
    object-fit: contain;
    user-select: none;

    background-image: ${props => `url(${props.imageURL})`};
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  & > div:nth-child(2) {
    padding-top: 5px;
    height: calc(100% - 200px);

    line-height: ${theme.fontSizes[1]};

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    // Title
    & > div:nth-child(2) {
      color: ${theme.colors.grey60};
      font-weight: 600;
    }

    // UserInfo
    & > div:nth-child(3) {
      margin-top: auto;
      margin-bottom: 0;

      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > :nth-child(1) {
        margin-right: 15px;
      }
    }
  }
`;
